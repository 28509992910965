<template>
  <div
    class="
      bg-white
      antialiased
      text-gray-600
      work-sans
      leading-normal
      text-base
      tracking-normal
    "
  >
    <!--Nav-->
    <div id="header" class="w-full top-0 py-1 shadow-md">
      <div
        class="
          w-full
          container
          z-50
          mx-auto
          flex flex-wrap
          items-center
          justify-between
          mt-0
          px-6
          py-0
        "
      >
        <label for="menu-toggle" class="cursor-pointer md:hidden block">
          <svg
            class="fill-current text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </label>
        <input class="hidden" type="checkbox" id="menu-toggle" />

        <div
          class="
            hidden
            md:flex
            md:items-center
            md:w-auto
            w-full
            order-1
            md:order-1
            z-40
          "
          id="menu"
        >
          <router-link to="/">
            <img class="home h-14" src="@/assets/experlogo.png" />
          </router-link>

          <nav>
            <ul
              class="
                md:flex
                items-center
                justify-between
                font-palanquin
                text-m text-gray-700
                pt-4
                md:pt-0
              "
            >
              <li>
                <a
                  href="https://www.experglobal.com"
                  class="
                    inline-block
                    no-underline
                    hover:text-black
                    hover:bg-gray-100
                    rounded-full
                    py-1
                    px-4
                  "
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="https://www.experglobal.com/about"
                  class="
                    inline-block
                    no-underline
                    hover:text-black
                    hover:bg-gray-100
                    rounded-full
                    py-1
                    px-4
                  "
                >
                  About us</a
                >
              </li>
              <li>
                <a
                  href="https://www.experglobal.com/services"
                  class="
                    inline-block
                    no-underline
                    hover:text-black
                    hover:bg-gray-100
                    rounded-full
                    py-1
                    px-4
                  "
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="https://www.experglobal.com/hq"
                  class="
                    inline-block
                    no-underline
                    hover:text-black
                    hover:bg-gray-100
                    rounded-full
                    py-1
                    px-4
                  "
                >
                  Headquarters
                </a>
              </li>
              <li class="relative">
                <span
                  class="
                    inline-block
                    flex
                    no-underline
                    hover:bg-gray-100
                    rounded-full
                    py-1
                    px-4
                    cursor-pointer
                  "
                  @click="showDropDown = !showDropDown"
                >
                  Partners
                  <svg
                    class="
                      fill-current
                      w-4
                      h-4
                      ml-1
                      -mr-1
                      mt-1
                      items-center
                      transform
                      group-hover:-rotate-180
                      transition
                      duration-150
                      ease-in-out
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>

                  <div
                    class="dropDown-overlay"
                    :class="{ hidden: !showDropDown }"
                  ></div>

                  <div
                    :class="{ hidden: !showDropDown }"
                    class="p-3 mt-8 bg-white shadow rounded w-40 absolute z-10"
                  >
                    <ul>
                      <li>
                        <a
                          @click="showDropDown = false"
                          href="https://www.experglobal.com/brands"
                          class="
                            hover:text-black
                            hover:bg-gray-100
                            rounded-full
                            block
                            px-3
                            py-1
                          "
                          >Partner Brands</a
                        >
                      </li>
                      <li>
                        <a
                          @click="showDropDown = false"
                          href="https://www.experglobal.com/partnership"
                          class="
                            hover:text-black
                            hover:bg-gray-100
                            rounded-full
                            block
                            px-3
                            py-1
                          "
                          >Partnership</a
                        >
                      </li>
                    </ul>
                  </div>
                </span>
              </li>

              <li>
                <a
                  href="https://www.experglobal.com/events"
                  class="
                    inline-block
                    no-underline
                    hover:text-black
                    hover:bg-gray-100
                    rounded-full
                    py-1
                    px-4
                  "
                >
                  Events & Newsletters
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="mobile-home order-last order-2 flex items-center">
          <a href="https://www.experglobal.com">
            <img class="h-12" src="@/assets/experlogo.png" />
          </a>
        </div>
        <div
          class="order-last order-2 md:order-2 flex items-center"
          id="nav-content"
        >
          <button
            style="background-color: #d3b986"
            class="
              inline-block
              px-4
              py-1
              mr-1
              text-m
              font-medium
              leading-6
              font-palanquin
              text-center text-white
              transition
              rounded-full
              ripple
              hover:shadow-lg
              hover:bg-yellow-600
              focus:outline-none
            "
          >
            <router-link to="/"> Portal </router-link>
          </button>
          <a
            href="https://www.experglobal.com/contact"
            class="
              home
              inline-block
              text-m
              no-underline
              font-palanquin
              hover:text-black
              hover:bg-gray-100
              rounded-full
              py-1
              px-4
            "
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "HeaderLayout",
  data() {
    return {
      showDropDown: false
    };
  },
  methods: {}
};
</script>

 
<style>
.dropDown-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.group:hover .group-hover\:scale-100 {
  transform: scale(1);
}
.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}
.scale-0 {
  transform: scale(0);
}
.min-w-32 {
  min-width: 8rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-home {
    display: block;
  }

  .home {
    display: none;
  }

  .homeslide {
    margin-top: -50px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }

  .homeslide {
    margin-top: -140px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }

  .homeslide {
    margin-top: -140px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }
  .homeslide {
    margin-top: -10px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }
  .homeslide {
    margin-top: -150px;
  }
}
</style>